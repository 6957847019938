import _ from 'lodash'
import { createTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import Color  from 'color'
import typography from './Typography'
import components from './Override'
import shadows from './Shadows'

// ##############################import {
import {
  DROPSMART_THEME,
  BLUE_THEME,
  GREEN_THEME,
  RED_THEME,
  BLACK_THEME,
  PURPLE_THEME,
  INDIGO_THEME,
  ORANGE_THEME
} from '../../redux/constants'
// // // Global Variables
// ##############################

const SidebarWidth = 265
const TopbarHeight = 64

const colors = {
  gray1: '#ffffff',
  gray2: '#F6F7FB',
  gray3: '#E6E9EE',
  gray4: '#CCD0D9',
  gray5: '#AAB2BD',
  gray6: '#434A54',
  gray7: '#2D2C31',
  gray8: '#000000',
  gray9: '#C4C4C4',
  gray10: '#ECECEC',
  white: '#ffffff',
  white1: '#F6F7FB',
  white2: '#E6E9EE',
  white3: '#CCD0D9',
  black1: '#AAB2BD',
  black2: '#434A54',
  black3: '#2D2C31',
  black: '#000000',
  red1: '#EA7D6E',
  red2: '#FF6651',
  red3: '#FC4A31',
  red4: '#FA361B',
  turquoise1: '#94EBF1',
  turquoise2: '#49E6F2',
  turquoise3: '#1BD2E0',
  turquoise4: '#00BECC',
  blue1: '#8FA5F2',
  blue2: '#577CFF',
  blue3: '#315DF9',
  blue4: '#6c08aa',
  purple1: '#e9c6fe',
  purple2: '#c770fc',
  purple3: '#a41afa',
  purple4: '#6c08aa',
  violet1: '#D28CFD',
  violet2: '#BD51FF',
  violet3: '#AB33F5',
  violet4: '#A41AFA',
  orange1: '#FAC26F',
  orange2: '#FFB23E',
  orange3: '#FCA524',
  orange4: '#FA9A0B',
  yellow1: '#F3D774',
  yellow2: '#FCD343',
  yellow3: '#F8C924',
  yellow4: '#EBBB0E',
  green1: '#76DA8C',
  green2: '#47D266',
  green3: '#29C84C',
  green4: '#21B642'
}

const mainColor = Color(process.env.REACT_APP_MAIN_COLOR);
const secondaryColor = Color(process.env.REACT_APP_SECONDARY_COLOR);
const successColor = Color(process.env.REACT_APP_SUCCESS_COLOR);
const dangerColor = Color(process.env.REACT_APP_DANGER_COLOR);
const warningColor = Color(process.env.REACT_APP_WARNING_COLOR);
const infoColor = Color(process.env.REACT_APP_INFO_COLOR);

const baseTheme = {
  direction: 'ltr',
  palette: {
    primary: {
      main: mainColor.hex() || colors.purple3,
      light: mainColor.lighten(0.3).hex()  || colors.purple2,
      ligthen: mainColor.lighten(0.5).hex()  || colors.purple2,
      bg: mainColor.lighten(0.85).hex()  || colors.purple2,
      dark: mainColor.darken(0.25).hex() || colors.purple4,
      desaturate: mainColor.darken(0.5).hex() || colors.purple1
    },
    secondary: {
      main: secondaryColor.hex() || colors.turquoise3,
      light: secondaryColor.lighten(0.5).hex() || colors.turquoise2,
      dark: secondaryColor.darken(0.25).hex() || colors.turquoise4,
      desaturate: secondaryColor.darken(0.5).hex() || colors.turquoise1
    },
    success: {
      main:  successColor.hex() || colors.green2,
      light: successColor.lighten(0.5).hex() || colors.green1,
      dark: successColor.darken(0.25).hex() || colors.green4,
      contrastText: '#ffffff'
    },
    // default from project
    danger: {
      main: dangerColor.hex() || colors.red2,
      dark: dangerColor.darken(0.25).hex() || colors.red4,
      light: dangerColor.lighten(0.5).hex() || colors.red1,
    },
    // default from project
    info: {
      main: infoColor.hex() || '#0bb2fb',
      dark: infoColor.darken(0.25).hex(),
      light: infoColor.lighten(0.5).hex() || '#a7e3f4',
    },
    error: {
      main: dangerColor.hex() || colors.red2,
      light: dangerColor.lighten(0.5).hex() || colors.red1,
      dark: dangerColor.darken(0.25).hex() || colors.red5
    },
    warning: {
      main: warningColor.hex() || colors.yellow2,
      light: warningColor.lighten(0.5).hex() || colors.yellow1,
      dark: warningColor.darken(0.25).hex() || colors.yellow5,
      contrastText: '#000000'
    },
    // default from project
    text: {
      secondary: '#777e89',
      white: colors.white,
      danger: '#fc4b6c'
    },
    grey: {
      A100: colors.gray1,
      A200: colors.gray2,
      A300: colors.gray3,
      A400: colors.gray4,
      A500: colors.gray5,
      A700: colors.gray7,
      card: colors.gray10
    },
    white: {
      main: colors.white
    },
    dark: {
      main: colors.A700
    },
    action: {
      disabledBackground: 'rgba(73,82,88,0.12)',
      hoverOpacity: 0.02,
      hover: 'rgba(0, 0, 0, 0.03)'
    }
  },

  shape: {
    borderRadius: 14
  },
  mixins: {
    toolbar: {
      color: '#949db2',
      '@media(min-width:1280px)': {
        minHeight: TopbarHeight,
        padding: '0 30px'
      },
      '@media(max-width:1280px)': {
        minHeight: '64px'
      }
    }
  },
  status: {
    danger: '#e53e3e'
  },
  components,
  typography,
  shadows
}

const themesOptions = [
  {
    name: DROPSMART_THEME,
    palette: {}
  },
  {
    name: BLUE_THEME,
    palette: {
      primary: {
        main: '#1a97f5',
        light: '#e6f4ff',
        dark: '#1682d4'
      },
      secondary: {
        main: '#1e4db7',
        light: '#ddebff',
        dark: '#173f98'
      }
    }
  },
  {
    name: GREEN_THEME,
    palette: {
      primary: {
        main: '#00cec3',
        light: '#d7f8f6',
        dark: '#02b3a9',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#066a73'
      }
    }
  },
  {
    name: PURPLE_THEME,
    palette: {
      primary: {
        main: '#7352ff',
        light: '#e5e0fa',
        dark: '#5739d6'
      },
      secondary: {
        main: '#402e8d'
      }
    }
  },
  {
    name: INDIGO_THEME,
    palette: {
      primary: {
        main: '#1e4db7',
        light: '#e6f4ff',
        dark: '#0c399e'
      },
      secondary: {
        main: '#11397b'
      }
    }
  },
  {
    name: ORANGE_THEME,
    palette: {
      primary: {
        main: '#03c9d7',
        light: '#e5fafb',
        dark: '#05b2bd',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#fb9678',
        light: '#fcf1ed',
        dark: '#e67e5f',
        contrastText: '#ffffff'
      }
    }
  },
  {
    name: RED_THEME,
    palette: {
      primary: {
        main: '#ff5c8e',
        light: '#fce6ed',
        dark: '#d43653',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#5e244d'
      }
    }
  },
  {
    name: BLACK_THEME,
    palette: {
      primary: {
        main: '#1c2025'
      }
    }
  }
]

export const BuildTheme = (config = {}) => {
  let themeOptions = themesOptions.find(theme => theme.name === config.theme)
  const customizer = useSelector(state => state.CustomizerReducer)

  const baseMode = {
    palette: {
      mode: customizer.activeMode,
      background: {
        default: customizer.activeMode === 'dark' ? '#20232a' : '#fafbfb',
        dark: customizer.activeMode === 'dark' ? '#1c2025' : '#ffffff',
        paper: customizer.activeMode === 'dark' ? '#282C34' : '#ffffff'
      },
      text: {
        primary:
          customizer.activeMode === 'dark' ? '#e6e5e8' : 'rgba(0, 0, 0, 0.87)',
        secondary: customizer.activeMode === 'dark' ? '#adb0bb' : '#777e89'
      }
    }
  }
  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`))
    ;[themeOptions] = themesOptions
  }

  const theme = createTheme(
    _.merge({}, baseTheme, baseMode, themeOptions, {
      direction: config.direction
    })
  )
  return theme
}

export { TopbarHeight, SidebarWidth, baseTheme }
