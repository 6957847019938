import {
    CREATE_STORE_ERROR
} from "../constants";

const INIT_STATE = {
    planLoading: false,
    dashboard: {}
};

const StoreReducer = (state = INIT_STATE, action) => {
    console.log(action);
    switch (action.type) {
        case CREATE_STORE_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: action.payload,
            };
        case "TOKEN_ERROR":
            return {
                ...state,
                loading: false,
                tokenErrorMessage: action.payload,
            };
        case "PLAN_ERROR":
            return {
                ...state,
                loading: false,
                planErrorMessage: action.payload,
            };
        case "PLAN_LOADING":
            return {
                ...state,
                planLoading: action.payload.loading,
            }
        case "DASHBOARD":
            return {
                ...state,
                dashboard: action.payload.dashboard
            }
        case "WHITELABEL":
            return {
                ...state,
                whitelabel: action.payload.whitelabel
            }
        default:
            return state;
    }
};

export default StoreReducer;